import { SVGProps } from "react";

const SvgLogoBpiFrance = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 568 163" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M464.88 53.13c-7.53 0-11.1 13.36-8.82 33.1 2.76 24.03 24.2 23.95 38.72 17.87l-.02 11.86c-8.92 2.43-16.2 3.04-22.5 3.04-36.6 0-40.33-30.1-40.33-39.1 0-15.28 5.42-36.4 34.43-36.4 19.24 0 30.22 12.52 30.4 29.3H473.6c-.04-10.47-2.24-19.64-8.72-19.64v-.03Zm77.7 17.22v-1.5c-.3-7.34-1.8-15.73-8.1-15.73-6.08 0-8.14 7.03-9 16.76l-.05.46h17.14l.01.01Zm24.46 10.5h-41.87l.02.44c1.5 29.6 23.52 28.97 39.06 22.8l-.01 11.88c-8.9 2.43-15.1 3.04-21.98 3.04-35.6 0-40.9-26.43-40.9-39.95 0-15.63 6.8-35.6 34-35.57 20.07.01 31.68 12.67 31.68 30.24V80.85Zm-250.36 25.92-.08-27.9-.46.04c-6 .53-14.8 3.23-14.8 15.33-.01 11.32 5.5 12.3 10.75 12.52l4.6.01h-.01Zm24.17 10-39.13-.01c-14.42-.02-24.8-5.88-24.78-21.86.02-13 7.54-19.26 16.22-22.3 4.35-1.52 8.98-2.22 13.1-2.55 4.12-.33 7.7-.27 9.92-.28h.42v-2.26c.02-16.72-16.67-14.93-26.1-12.18-1.8.55-3.43 1.13-4.8 1.7V46.1c8.33-1.96 14-2.63 22.85-2.63 20.93 0 32.3 9.82 32.3 26.23v47.07ZM276.5 53.94c-5.72.26-14.5 2.3-14.5 15.66l-.04 47.17H237.7l.03-47.94c.02-14.4 13.2-25.35 33.83-25.35h4.92l.01 10.46h.01Zm-39.15-37.9-.01 10.27-7.07.18c-2.43.2-4.74.6-6.74 1.42-4.08 1.7-6.8 5.15-6.8 11.97v6.05h17.1l-.01 10.4h-17.14l.02 60.44h-24.23v-71.3c0-26.35 18.07-29.44 39.97-29.44h4.9l.01.01Zm183.8 100.74h-24.38l-.05-42.68c0-16.82-6.38-17.7-15.26-17.75h-1.72l.02 60.43h-24.3l-.02-71.18c1.97.13 8.54.34 30.45.34 35.13 0 35.23 16.3 35.24 29.55l.01 41.3.01-.01Zm-256.32 45.98c-8.62 0-15.6-7-15.6-15.63s7-15.63 15.6-15.63 15.6 7 15.6 15.63-7 15.63-15.6 15.63Z"
        fill="#FFCD00"
      />
      <path
        d="m176.97 45.92-.04 70.85h-24.25l.02-70.85h24.27Zm-12.14-14.64c-8.62 0-15.6-7-15.6-15.62s7-15.63 15.6-15.63 15.6 7 15.6 15.63-7 15.62-15.6 15.62ZM41.2 80.5c0-15.46-3.6-24.98-14.72-24.98h-2V107h2.35c10.8 0 14.36-10.3 14.36-26.5h.01ZM.1 116.76V16.03h24.38l-.01 29.9h7.72c16.9 0 25.25 6.6 29.4 14.17 4.2 7.62 4.2 16.3 4.2 20.58 0 11.24-3.55 36.1-31.4 36.1l-34.3-.02H.1ZM100.8 55.5V107h2c11.13 0 14.7-9.52 14.7-25 0-16.18-3.57-26.5-14.36-26.5h-2.34Zm41.32 26.52c0 4.27 0 12.96-4.2 20.58-4.17 7.57-12.52 14.17-29.4 14.17h-7.72v29.9H76.43V45.93h34.3c27.86-.01 31.4 24.85 31.4 36.1l-.01-.01Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default SvgLogoBpiFrance;
