import { t, Trans } from "@lingui/macro";
import ForumIcon from "@mui/icons-material/Forum";
import { useRef } from "react";
import { PATHS } from "../constants/paths";
import { useGeneratePrefixedPath } from "../hooks";
import MenuItem from "../admin/MenuItem";

type MenuProps = {
  shownDesktopMenu: boolean;
};

/**
 * Administration menu.
 */
function Menu({ shownDesktopMenu }: MenuProps) {
  const generatePrefixedPath = useGeneratePrefixedPath();
  const titleButtonsRef = useRef<HTMLElement[]>([]);

  function handlePreviousTitleButton() {
    for (let i = 1; i < titleButtonsRef.current.length; i++) {
      if (titleButtonsRef.current[i] === document.activeElement) {
        titleButtonsRef.current[i - 1].focus();
        break;
      }
    }
  }
  function handleNextTitleButton() {
    for (let i = 0; i < titleButtonsRef.current.length - 1; i++) {
      if (titleButtonsRef.current[i] === document.activeElement) {
        titleButtonsRef.current[i + 1].focus();
        break;
      }
    }
  }
  function handleFirstTitleButton() {
    titleButtonsRef.current[0].focus();
  }
  function handleLastTitleButton() {
    titleButtonsRef.current[titleButtonsRef.current.length - 1].focus();
  }
  function setTitleButtonRef(
    index: number,
  ): (element: HTMLButtonElement) => void {
    return (element) => {
      titleButtonsRef.current[index] = element;
    };
  }
  function generteKeyDownProps(index: number) {
    return {
      onNextTitleButton: handleNextTitleButton,
      onPreviousTitleButton: handlePreviousTitleButton,
      onFirstTitleButton: handleFirstTitleButton,
      onLastTitleButton: handleLastTitleButton,
      setTitleButtonRef: setTitleButtonRef(index),
    };
  }
  return (
    <nav role="navigation" id="navigation">
      <h1 className="admin-navigation__title">
        <Trans>Main menu</Trans>
      </h1>
      <ul className="admin-navigation__list">
        <MenuItem
          itemText={t`Mediation`}
          ItemIcon={ForumIcon}
          id="mediation"
          shownDesktopMenu={shownDesktopMenu}
          subItems={[
            {
              text: t`All requests`,
              link: generatePrefixedPath(PATHS.MEDIATION_ALL_REQUESTS),
            },
          ]}
          {...generteKeyDownProps(0)}
        />
      </ul>
    </nav>
  );
}

export default Menu;
