import { useLingui } from "@lingui/react";
import { useOrganizationApp } from "../hooks";
import { extractTranslation } from "../i18nHelper";
import type { OrganizationAppRecieved } from "../types/organizationApp";
import type { Langs } from "../types/types";

type CustomOrganizationTextProps = {
  /**
   * The organization applicaiton data got from the backend for the first time.
   */
  initialOrganizationApp?: OrganizationAppRecieved;
};

/**
 * The text displayed at the top of the main mediation form and describing
 * the organization application.
 */
function CustomOrganizationText({
  initialOrganizationApp,
}: CustomOrganizationTextProps) {
  const { organizationApp } = useOrganizationApp(initialOrganizationApp);
  const { i18n } = useLingui();
  const description =
    organizationApp &&
    extractTranslation(i18n.locale as Langs, organizationApp.description);
  return description ? (
    <div className="custom-organization-text">
      <div
        className="custom-organization-text__content"
        dangerouslySetInnerHTML={{ __html: description }}
      />{" "}
    </div>
  ) : null;
}

export default CustomOrganizationText;
