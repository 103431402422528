import { t } from "@lingui/macro";
import { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { PATHS, Paths } from "../constants/paths";
import ConfigDataContext from "../contexts/configData";
import Page from "../Page";
import { ConfigData } from "../types/types";
import AllRequests from "./AllRequests";
import RequestDetailContainer from "../admin/requestDetail/RequestDetailContainer";

type MainProps = {
  token: string;
  paths: Paths;
};

/**
 * Main admin content doing the routing between admin pages.
 */
function Main({ token, paths }: MainProps) {
  const configData = useContext<ConfigData>(ConfigDataContext);
  return (
    <main role="main" id="main" className="admin-layout__main admin-main">
      <Switch>
        <Route path={paths.MEDIATION_ALL_REQUESTS} exact>
          <Page title={t`${configData.platformName} - All mediation requests`}>
            <AllRequests token={token} />
          </Page>
        </Route>
        <Route path={paths.MEDIATION_REQUEST_DETAIL}>
          <RequestDetailContainer
            token={token}
            traceReportPath={PATHS.MEDIATION_TRACE_REPORTS}
            requestDetailPath={PATHS.MEDIATION_REQUEST_DETAIL}
          />
        </Route>
      </Switch>
    </main>
  );
}

export default Main;
