import { useEffect } from "react";
import { useOrganizationApp } from "../hooks";
import type { OrganizationAppRecieved } from "../types/organizationApp";

type ColorChangerProps = {
  /**
   * The organization applicaiton data got from the backend for the first time.
   */
  initialOrganizationApp?: OrganizationAppRecieved;
};

/**
 * Changes CSS variables values from values found in organization
 * application colors, that we get from the backend.
 */
function ColorChanger({ initialOrganizationApp }: ColorChangerProps): null {
  const { organizationApp } = useOrganizationApp(initialOrganizationApp);
  useEffect(() => {
    if (organizationApp) {
      document.documentElement.style.setProperty(
        "--secondary-text-color",
        organizationApp.secondaryTextColor
      );
      document.documentElement.style.setProperty(
        "--button-background-color",
        organizationApp.buttonBackgroundColor
      );
      document.documentElement.style.setProperty(
        "--button-hover-color",
        organizationApp.buttonHoverColor
      );
      document.documentElement.style.setProperty(
        "--outlined-button-hover-color",
        organizationApp.outlinedButtonHoverColor
      );
      document.documentElement.style.setProperty(
        "--border-color",
        organizationApp.borderColor
      );
      document.documentElement.style.setProperty(
        "--step-color",
        organizationApp.stepColor
      );
      document.documentElement.style.setProperty(
        "--header-color",
        organizationApp.headerColor
      );
      document.documentElement.style.setProperty(
        "--footer-color",
        organizationApp.footerColor
      );
    }
  }, [organizationApp]);
  return null;
}

export default ColorChanger;
