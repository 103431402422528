import { useLingui } from "@lingui/react";
import { useOrganizationApp } from "../hooks";
import { extractTranslation } from "../i18nHelper";
import type { OrganizationAppRecieved } from "../types/organizationApp";
import type { Langs } from "../types/types";

type LogoProps = {
  /**
   * The organization applicaiton data got from the backend for the first time.
   */
  initialOrganizationApp?: OrganizationAppRecieved;
};

/**
 * The logo of the organization application displayed at the top of
 * the main mediation form.
 */
function Logo({ initialOrganizationApp }: LogoProps) {
  const { organizationApp } = useOrganizationApp(initialOrganizationApp);
  const { i18n } = useLingui();
  const logoPath = organizationApp && organizationApp.logo;
  const altLogo =
    organizationApp &&
    extractTranslation(i18n.locale as Langs, organizationApp.logoAlternative);
  return Boolean(logoPath && altLogo) ? (
    <img className="organization-logo" src={logoPath} alt={altLogo} />
  ) : null;
}

export default Logo;
