export const APP_PREFIX = "/:organizationSlug?/:applicationSlug?";

type PathsKeys =
  | "ROOT"
  | "PROBLEM_DESCRIPTION"
  | "ORGANIZATION_INFO"
  | "RECAP"
  | "LOGIN"
  | "REGISTER"
  | "RESET_PASSWORD"
  | "RESET_PASSWORD_CONFIRM"
  | "USER_DETAILS"
  | "USER_REQUESTS"
  | "USER_REQUEST"
  | "TERMS_OF_SERVICE"
  | "MEDIATION"
  | "MEDIATION_ALL_REQUESTS"
  | "MEDIATION_REQUEST_DETAIL"
  | "MEDIATION_TRACE_REPORTS"
  | "MEDIATION_REQUEST_HISTORY"
  | "ADMIN"
  | "ADMIN_ALL_REQUESTS"
  | "ADMIN_REQUEST_DETAIL"
  | "ADMIN_TRACE_REPORTS"
  | "ADMIN_REQUEST_HISTORY";

type Paths = Record<PathsKeys, string>;

export const PATHS_WITHOUT_PREFIX: Paths = {
  ROOT: "/",
  PROBLEM_DESCRIPTION: "/problem-description",
  ORGANIZATION_INFO: "/organization-info",
  RECAP: "/recap",
  LOGIN: "/login",
  REGISTER: "/register",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_CONFIRM: "/reset-password/confirm/:uid/:token",
  USER_DETAILS: "/user-details",
  USER_REQUESTS: "/user-requests",
  USER_REQUEST: "/user-requests/:requestId",
  TERMS_OF_SERVICE: "/terms-of-service",
  MEDIATION: "/mediation",
  MEDIATION_ALL_REQUESTS: "/mediation/all-requests",
  MEDIATION_REQUEST_DETAIL: "/mediation/all-requests/:requestId",
  MEDIATION_TRACE_REPORTS: "/mediation/all-requests/:requestId/trace-reports",
  MEDIATION_REQUEST_HISTORY: "/mediation/all-requests/:requestId/history",
  ADMIN: "/admin",
  ADMIN_ALL_REQUESTS: "/admin/all-requests",
  ADMIN_REQUEST_DETAIL: "/admin/all-requests/:requestId",
  ADMIN_TRACE_REPORTS: "/admin/all-requests/:requestId/trace-reports",
  ADMIN_REQUEST_HISTORY: "/admin/all-requests/:requestId/history",
} as const;

export const PATHS: Paths = {
  ROOT: `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.ROOT}`,
  PROBLEM_DESCRIPTION:
    `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.PROBLEM_DESCRIPTION}`,
  ORGANIZATION_INFO: `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.ORGANIZATION_INFO}`,
  RECAP: `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.RECAP}`,
  LOGIN: `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.LOGIN}`,
  REGISTER: `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.REGISTER}`,
  RESET_PASSWORD: `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.RESET_PASSWORD}`,
  RESET_PASSWORD_CONFIRM:
    `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.RESET_PASSWORD_CONFIRM}`,
  USER_DETAILS: `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.USER_DETAILS}`,
  USER_REQUESTS: `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.USER_REQUESTS}`,
  USER_REQUEST: `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.USER_REQUEST}`,
  TERMS_OF_SERVICE: `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.TERMS_OF_SERVICE}`,
  MEDIATION: `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.MEDIATION}`,
  MEDIATION_ALL_REQUESTS:
    `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.MEDIATION_ALL_REQUESTS}`,
  MEDIATION_REQUEST_DETAIL:
    `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.MEDIATION_REQUEST_DETAIL}`,
  MEDIATION_TRACE_REPORTS:
    `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.MEDIATION_TRACE_REPORTS}`,
  MEDIATION_REQUEST_HISTORY:
    `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.MEDIATION_REQUEST_HISTORY}`,
  ADMIN: `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.ADMIN}`,
  ADMIN_ALL_REQUESTS: `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.ADMIN_ALL_REQUESTS}`,
  ADMIN_REQUEST_DETAIL:
    `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.ADMIN_REQUEST_DETAIL}`,
  ADMIN_TRACE_REPORTS:
    `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.ADMIN_TRACE_REPORTS}`,
  ADMIN_REQUEST_HISTORY:
    `${APP_PREFIX}${PATHS_WITHOUT_PREFIX.ADMIN_REQUEST_HISTORY}`,
} as const;

export const PATHS_MANDATORY_LOGIN = [
  PATHS.USER_DETAILS,
  PATHS.USER_REQUESTS,
  PATHS.USER_REQUEST,
] as const;

export type { Paths, PathsKeys };
